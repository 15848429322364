import React from 'react';
import { useTranslation } from 'react-i18next';
import { DragObjectWithType, useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamationCircle, faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { isPlannedDateExceeded } from '@ssg/common/Helpers/drivingSlipsHelper';
import classNames from 'classnames';
import dateToDateTimeString from '@ssg/common/Helpers/dateToDateTimeString';
import Popover from '@ssg/common/Components/Popover';
import TextButton from '@ssg/common/Components/TextButton';
import { MergedDrivingSlip } from './PlannerDrivingSlips';
import { DrivingSlipInput } from '@ssg/common/GraphQL/indexV2';
import { DragItemTypes, useCanEdit, usePlannerDrivingSlips } from './PlannerHelpers';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PlannerDrivingSlipRequisitionModal from './PlannerDrivingSlipRequisitionModal';
import PlannerEditDrivingSlip from './PlannerEditDrivingSlip';

interface FormInput extends Omit<DrivingSlipInput, 'driver' | 'end'> {
	driver: string[];
	plannedDuration?: number;
	immediatelyPlan: boolean;
}


interface Props {
	drivingSlip: MergedDrivingSlip;
	setIsDroppedArray: React.Dispatch<React.SetStateAction<string[]>>
}

export interface DragPropsDrivingSlip extends DragObjectWithType {
	drivingSlip: MergedDrivingSlip;
}

const PlannerDrivingSlipCard: React.FC<Props> = ({ drivingSlip, setIsDroppedArray }): React.ReactElement => {
	const { t } = useTranslation();

	const canEdit = useCanEdit();


	const [editId, setEditId] = React.useState<string>();
	//const [driverSearchText, setDriverSearchText] = React.useState(drivingSlip.driver?.name ?? '');
	const [convertToRequisition, setConvertToRequisition] = React.useState(false);

	const [drivingSlipId, setDrivingSlipId] = React.useState('');
	const { updateSingleDrivingSlip, updateDrivingSlipSubmitting, deleteDrivingSlipSubmitting, deleteSingleDrivingSlip } = usePlannerDrivingSlips();

	// const searchSelectHandler = (value: string): void => {
	// 	setValue('driver', [value], { shouldValidate: true });
	// };

	// const searchedUsers: SelectOption[] = React.useMemo(() => {
	// 	let thisUsersWithCalendars = usersWithCalendars;
	// 	const fuse = new Fuse(usersWithCalendars, {
	// 		shouldSort: true,
	// 		threshold: 0.2,
	// 		keys: ['name'],
	// 	});

	// 	if (driverSearchText.length > 0) {
	// 		thisUsersWithCalendars = fuse.search(driverSearchText).map(v => v.item);
	// 	}

	// 	const mappedUsersWithCalendars = thisUsersWithCalendars.map((s): SelectOption => ({ value: s.id, label: s.name }));
	// 	return mappedUsersWithCalendars;
	// }, [usersWithCalendars, driverSearchText]);

	const [{ isDragging }, dragRef, preview] = useDrag<DragPropsDrivingSlip, void, { isDragging: boolean }>({
		item: {
			type: DragItemTypes.DRIVING_SLIP,
			drivingSlip: drivingSlip,
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		end(draggedItem, monitor) {
			if (draggedItem) {
				const didDrop = monitor.didDrop();
				const dropResult = monitor.getDropResult() as { result: 'success' | 'denied' } | null;
				if (dropResult && dropResult.result === 'success') {
					if (didDrop) {
						const ids = draggedItem.drivingSlip.merged.map(m => m.id);
						setIsDroppedArray(current => [...current, ...ids]);
					}
				}
			}
		},
	});
	// Used to hide the Drivngslip Card preview on drag
	React.useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const ref = canEdit ? dragRef : null;



	const plannedDateExceeded = React.useMemo(() => drivingSlip.merged.some(({ start }) => isPlannedDateExceeded(start)), [drivingSlip.merged]);

	return (
		<>
			<div
				ref={ref}
				key={drivingSlip.series}
				className={classNames('mb-2 w-full bg-white text-xs border-2 p-2', {
					'border-orange': drivingSlip.urgent && !plannedDateExceeded,
					'border-purple': drivingSlip.case.skafor && !drivingSlip.urgent && !plannedDateExceeded,
					'border-red': plannedDateExceeded,
					'opacity-50': isDragging,
				})}
			>
				{(drivingSlip.urgent || drivingSlip.case.skafor || plannedDateExceeded) && (
					<div className="flex w-full space-x-3">
						{plannedDateExceeded && (
							<div className="text-red mb-1 mr-3 flex flex-row items-center font-bold">
								<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
								<p className="ml-1">{t('common.overdue')}</p>
							</div>
						)}

						{drivingSlip.urgent && (
							<div className="text-orange mb-1 mr-3 flex flex-row items-center font-bold">
								<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
								<p className="ml-1">{t('common.urgent')}</p>
							</div>
						)}

						{drivingSlip.case.skafor && (
							<div className="text-purple mb-1 mr-3 flex flex-row items-center font-bold">
								<FontAwesomeIcon icon={faExclamationCircle} size="lg" />
								<p className="ml-1">{t('case.SKAFOR')}</p>
							</div>
						)}
					</div>
				)}
				{drivingSlip.plannerMessage &&
					<div className="text-blue mb-1 mr-3 flex flex-row items-center">
						<FontAwesomeIcon icon={faCommentLines} size="lg" />
						<p className="ml-1">{drivingSlip.plannerMessage}</p>
					</div>
				}
				<div className="w-full flex flex-row">
					<div className="flex-1">
						<p className="font-semibold">{t('common.case')}</p>
						<Link to={`/case/${drivingSlip.case.id}`} target="_blank" className="hover:underline">
							<p>
								{drivingSlip.case.erpNo}
							</p>
						</Link>
						<p>{drivingSlip.case.debitor.company}</p>
						<p>
							<span className="font-semibold">{t('common.damageCategory')}:</span> {drivingSlip.case.damage.category.name}
						</p>
						<p>
							<span className="font-semibold">{t('case.damageCause')}:</span> {drivingSlip.case.damage.cause.name}
						</p>

					</div>

					<div className="flex-1">
						<p className="font-semibold">{t('case.injuredParty')}</p>
						<p>{drivingSlip.case.damage.contact.name}</p>
						<a
							href={`tel:${drivingSlip.case.damage.contact.phone}`}
							className={classNames('hover:underline', {
								hidden: !drivingSlip.case.damage.contact.phone,
							})}
						>
							{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.damage.contact.phone)}
						</a>
						<p>
							{drivingSlip.case.damage.contact.address.road} {drivingSlip.case.damage.contact.address.houseNumber} {drivingSlip.case.damage.contact.address.floor ?? ''}
						</p>
						<p>{`${drivingSlip.case.damage.contact.address.postalCode} ${drivingSlip.case.damage.contact.address.city}`}</p>
					</div>
				</div>

				<div className="mt-2">
					<p><span className="font-semibold">{t('case.ssgProjectManager')}:</span> {drivingSlip.case.projectManager?.name}</p>
					<a
						href={`tel:${drivingSlip.case.projectManager?.phone}`}
						className={classNames('hover:underline', {
							hidden: !drivingSlip.case.projectManager?.phone,
						})}
					>
						{t('common.tel')} {formatPhoneNumberIntl(drivingSlip.case.projectManager?.phone ?? '')}
					</a>
					{/* <div className="w-full">
						<p>
							<span className="font-semibold">{t('drivingSlips.requestedDriver')}: </span>{drivingSlip.driver !== null ? drivingSlip.driver.name : t('common.noneSpecified')}
						</p>

					</div> */}
				</div>
				<div className="mt-2 w-full">
					<p>
						<span className="font-semibold">{t('planner.preferredTo')}:</span>
					</p>
					{drivingSlip.merged
						.slice()
						.map(({ id, start, comment, driver, estimatedHours, car }, index, arr) => (
							<div key={id} className={classNames('mb-1 pb-px ', { 'border-b-1': index !== arr.length - 1 })}>
								<Popover
									placement="bottom"
									content={
										<div className="rounded-b-default border-1 shadow-default border-black bg-white p-2 text-xs">
											{/* <p className="font-semibold text-sm">{t('case.drivenBy')}:</p>
											{driver?.name ?? ''} */}
											<p className=" text-sm font-semibold">{t('common.comment')}:</p>
											{comment ?? ''}
										</div>
									}
								>
									<div className="flex flex-row justify-between"><p>{dateToDateTimeString(start)} ({estimatedHours !== null ? estimatedHours === 3.75 ? <span className="font-bold">H/D</span> : estimatedHours : '-'}): {t(driver?.name ?? 'common.noneSpecified')}</p> <TextButton icon={faEdit} onClick={() => setEditId(canEdit ? id : undefined)} className="ml-2 mr-2" textClassName="text-xs" iconSize="sm" /></div>
								</Popover>

								{/* {drivingSlip.status !== DrivingSlipStatus.Completed && drivingSlip.status !== DrivingSlipStatus.Obsolete && (
									<div className="inline-flex">
										<TextButton icon={faEdit} text="common.edit" onClick={() => setEditId(canEdit ? id : undefined)} className="ml-2 mr-2" textClassName="text-xs" iconSize="sm" />
										<TextButton
											text="common.convert"
											icon={faExchange}
											onClick={() => {
												setDrivingSlipId(id);
												setConvertToRequisition(true);
											}}
											textClassName="text-xs"
											iconSize="sm"
										/>
									</div>
								)} */}
							</div>
						))}
				</div>
			</div>
			{editId && <PlannerEditDrivingSlip drivingSlip={drivingSlip} editId={editId} visible={typeof editId !== 'undefined'} close={() => setEditId(undefined)} />}
			{/*
			{seeRiskEval && (
				<Modal
					title="case.riskEvaluation"
					visible={seeRiskEval}
					close={() => setSeeRiskEval(false)}
					size={ModalSize.XLARGE}
					body={
						<div>
							{riskCategoriesLoading || riskQuestionsLoading || answersLoading ? (
								<div className="relative h-40">
									<Loading />
								</div>
							) : (
								<div className="flex flex-wrap">
									{riskEvaluationCategories.length > 0 ? (
										riskEvaluationCategories.map(c => {
											return (
												<div className="w-1/3" key={c.categoryName}>
													<FormFieldHeader title={c.categoryName} />
													{c.questions.map((q, i) => {
														return (
															<div key={i}>
																<p>
																	{q.question} - <span className="font-semibold">{q.answer ? t('common.yes') : t('common.no')}</span>
																</p>
															</div>
														);
													})}
												</div>
											);
										})
									) : (
										<div>{t('case.noRiskEvaluation')}</div>
									)}
								</div>
							)}
						</div>
					}
				/>
			)}
			)} */}

			{drivingSlipId !== '' && convertToRequisition && (
				<PlannerDrivingSlipRequisitionModal
					visible={convertToRequisition}
					close={() => {
						setConvertToRequisition(false);
						setDrivingSlipId('');
					}}
					drivingSlipId={drivingSlipId}
					comment={drivingSlip.merged.find(d => d.id === drivingSlipId)?.comment ?? ''}
					caseId={drivingSlip.case.id}
				/>
			)}
		</>
	);
};

export default PlannerDrivingSlipCard;
