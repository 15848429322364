import React from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CheckMovable, CheckMovableVariables, GetMovable, GetMovables, GetMovableStatusOptions, GetMovableVariables, UpdateMovable, UpdateMovableVariables } from '../../GraphQL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faPlus, faPrint, faScanner, faSpinner, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { MOVABLE_BOX_PREFIX, MOVABLE_CASE_PREFIX, MOVABLE_MOVABLE_PREFIX, MOVABLE_OTHER_PREFIX, MOVABLE_PLACEMENT_PREFIX, MOVABLE_STATUS_PREFIX } from './movablePrefixes';
import Modal, { ModalSize } from '@ssg/common/Components/Modal';
import Header from '@ssg/common/Components/Header';
import classNames from 'classnames';
import Movables from './Movables';
import MovableJobs from './MovableJobs';
import Button from '@ssg/common/Components/Button';
import CreateMovable from './CreateMovable';
import BarcodeScanner from './BarcodeScannerTwo';
import CreateCaseLabel from './CreateCaseLabel';
import EditMovable from './EditMovable';
import CreateMovableJob from './CreateMovableJob';
import MovableReprint from './MovableReprint';
import MovableExportOverview from './MovableExportOverview';
import TextButton from '@ssg/common/Components/TextButton';
import ChangeLabelAndStatus from './ChangeLabelAndStatus';
import { GetMovablesQueryVariables } from '@ssg/common/GraphQL/indexV2';

const GET_MOVABLE = loader('src/GraphQL/Movables/GetMovable.gql');
const GET_MOVABLES = loader('src/GraphQL/Movables/GetMovables.gql');
const UPDATE_MOVABLE = loader('src/GraphQL/Movables/UpdateMovable.gql');
const CHECK_MOVABLE = loader('src/GraphQL/Movables/CheckMovable.gql');
const GET_MOVABLE_STATUS_OPTIONS = loader('src/GraphQL/Movables/GetMovableStatusOptions.gql');

export interface MovableActionButtonProps {
	id: GetMovables['movables'][number]['id'];
	status: string;
	callback?: () => unknown;
	className?: string;
}

interface ScannerProps {
	mode: 'HIDE' | 'SINGLE' | 'MULTIPLE' | 'WORKING';
	title: string;
	allowedPrefixes?: string[];
}

const tabs = ['common.movable', 'jobs.overviewTitle', 'movable.exportOverviewTitle'];

const MovablesTabs: React.FC = (): React.ReactElement => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

	const [checkMovable] = useMutation<CheckMovable, CheckMovableVariables>(CHECK_MOVABLE);
	const [getStatusOptions, { data: movableStatusOptions }] = useLazyQuery<GetMovableStatusOptions>(GET_MOVABLE_STATUS_OPTIONS, {
		fetchPolicy: 'cache-first',
	});

	const [movablesVariables, setMovablesVariables] = React.useState<GetMovablesQueryVariables>();

	const [showScannerModal, setShowScannerModal] = React.useState<ScannerProps>({ mode: 'HIDE', title: '' });
	const [showCreatePrintLabelModal, setShowCreatePrintLabelModal] = React.useState(false);
	const [scannedId, setScannedId] = React.useState<string | undefined>(undefined);
	const scannedIdListRef = React.useRef<string[] | null>(null);

	const [showCreateMovableModal, setShowCreateMovableModal] = React.useState<{
		type: 'BOX' | 'OTHER';
		movableId: string;
		status: string;
	}>();
	const [showEditMovableModal, setShowEditMovableModal] = React.useState<{
		id: GetMovables['movables'][number]['id'];
		status: string;
	}>();
	const [caseInfo, setCaseInfo] = React.useState<{ id: string; status: string } | undefined>(undefined);
	const [movableInfo, setMovableInfo] = React.useState<{ id: string; labelStatus: string; currentStatus: string } | undefined>(undefined);

	const [showCreateMovableJobModal, setShowCreateMovableJobModal] = React.useState(false);
	const [updateMovable] = useMutation<UpdateMovable, UpdateMovableVariables>(UPDATE_MOVABLE);

	React.useEffect(() => {
		async function checkMovableExistance(id: string): Promise<{
			registratonStatus: 'UNREGISTERED' | 'ACTIVE' | 'COMPLETED';
			currentStatus: string | null;
		}> {
			const { data } = await checkMovable({ variables: { id } });
			if (typeof data === 'undefined' || data === null || data.checkMovable === null) {
				return {
					registratonStatus: 'UNREGISTERED',
					currentStatus: null,
				};
			} else if (data.checkMovable.completed) {
				return { registratonStatus: 'COMPLETED', currentStatus: null };
			}
			return {
				registratonStatus: 'ACTIVE',
				currentStatus: data.checkMovable.status,
			};
		}

		if (typeof scannedId !== 'undefined' && showScannerModal.mode === 'SINGLE') {
			setShowScannerModal({ mode: 'WORKING', title: '' });

			if (Array.isArray(scannedIdListRef.current) && scannedId.startsWith(MOVABLE_PLACEMENT_PREFIX)) {
				const placement = scannedId.substring(MOVABLE_PLACEMENT_PREFIX.length);

				const idList = scannedIdListRef.current.map(id => {
					const [, movablePart] = id.split('|');
					return movablePart.substring(MOVABLE_MOVABLE_PREFIX.length);
				});
				Promise.all(idList.map(id => checkMovableExistance(id)))
					.then(movableExistanceValues => {
						if (movableExistanceValues.some(mev => mev.registratonStatus !== 'ACTIVE')) {
							return window.alert(t('movable.oneOrMoreMovablesNotActive'));
						}

						return idList.map(id =>
							updateMovable({
								variables: {
									id,
									placement,
								},
							}),
						);
					})
					.then(() => {
						scannedIdListRef.current = null;
						setShowScannerModal({ mode: 'HIDE', title: '' });
					});
			} else if (scannedId.startsWith(MOVABLE_CASE_PREFIX)) {
				const [casePart, movablePart, statusPart] = scannedId.split('|');

				const status = statusPart.startsWith(MOVABLE_STATUS_PREFIX) ? statusPart.substring(MOVABLE_STATUS_PREFIX.length) : '';

				if (casePart.startsWith(MOVABLE_CASE_PREFIX)) {
					setCaseInfo({
						id: casePart.substring(MOVABLE_CASE_PREFIX.length),
						status,
					});
				}

				if (movablePart.startsWith(MOVABLE_MOVABLE_PREFIX)) {
					const id = movablePart.substring(MOVABLE_MOVABLE_PREFIX.length);
					checkMovableExistance(id).then(({ registratonStatus, currentStatus }) => {
						if (registratonStatus === 'ACTIVE') {
							getStatusOptions();
							setMovableInfo({
								id,
								labelStatus: status,
								currentStatus: currentStatus ?? '',
							});

							setShowScannerModal({
								mode: 'HIDE',
								title: '',
							});
						}
					});
				} else if (movablePart.startsWith(MOVABLE_BOX_PREFIX)) {
					const id = movablePart.substring(MOVABLE_BOX_PREFIX.length);
					checkMovableExistance(id).then(({ registratonStatus, currentStatus }) => {
						if (registratonStatus === 'ACTIVE') {
							getStatusOptions();
							setMovableInfo({
								id,
								labelStatus: status,
								currentStatus: currentStatus ?? '',
							});
						} else if (registratonStatus === 'UNREGISTERED') {
							setShowCreateMovableModal({
								type: 'BOX',
								movableId: id,
								status,
							});
						} else if (registratonStatus === 'COMPLETED') {
							setCaseInfo(undefined);
							window.alert(t('movable.alertCompleteMessage'));
						}

						setShowScannerModal({ mode: 'HIDE', title: '' });
					});
				} else if (movablePart.startsWith(MOVABLE_OTHER_PREFIX)) {
					const id = movablePart.substring(MOVABLE_OTHER_PREFIX.length);
					checkMovableExistance(id).then(({ registratonStatus, currentStatus }) => {
						if (registratonStatus === 'ACTIVE') {
							getStatusOptions();
							setMovableInfo({
								id,
								labelStatus: status,
								currentStatus: currentStatus ?? '',
							});
						} else if (registratonStatus === 'UNREGISTERED') {
							setShowCreateMovableModal({
								type: 'OTHER',
								movableId: id,
								status,
							});
						} else if (registratonStatus === 'COMPLETED') {
							setCaseInfo(undefined);
							window.alert(t('movable.alertCompleteMessage'));
						}

						setShowScannerModal({ mode: 'HIDE', title: '' });
					});
				}
			}
		}
	}, [checkMovable, getStatusOptions, scannedId, showScannerModal, t, updateMovable]);

	React.useEffect(() => {
		if (!showCreateMovableModal) {
			setCaseInfo(undefined);
		}
	}, [showCreateMovableModal]);

	const { data: editMovable } = useQuery<GetMovable, GetMovableVariables>(GET_MOVABLE, {
		variables: { id: showEditMovableModal?.id ?? '' },
		skip: typeof showEditMovableModal === 'undefined' || showEditMovableModal.id.length === 0,
	});

	const MovableEditButton: React.FC<MovableActionButtonProps> = ({ id, status, callback, className }) => (
		<Button
			primary
			icon={faEdit}
			className={className}
			onClick={() => {
				setShowEditMovableModal({ id, status });
				callback?.();
			}}
		/>
	);

	const MovableCompleteButton: React.FC<MovableActionButtonProps> = ({ id, callback, className }) => (
		<Button
			success
			icon={faCheck}
			className={className}
			onClick={async () => {
				if (window.confirm(t('movable.confirmCompleteMessage'))) {
					await updateMovable({
						variables: {
							id,
							completed: true,
						},
						update: (cache, { data }): void => {
							if (typeof data === 'undefined' || data === null) {
								return;
							}

							// Update cache
							const cachedRequest = cache.readQuery<GetMovables>({
								query: GET_MOVABLES,
							});
							if (cachedRequest === null) {
								return;
							}

							cache.writeQuery({
								query: GET_MOVABLES,
								data: {
									movables: cachedRequest.movables.filter(cm => cm.id !== id),
								},
							});
						},
					});
					callback?.();
				}
			}}
		/>
	);

	const MovableReviveButton: React.FC<MovableActionButtonProps> = ({ id, callback, className }) => (
		<Button
			secondary
			icon={faUndo}
			className={className}
			onClick={async () => {
				if (window.confirm(t('movable.confirmReviveMessage'))) {
					await updateMovable({
						variables: {
							id,
							completed: false,
						},
						update: (cache, { data }): void => {
							if (typeof data === 'undefined' || data === null) {
								return;
							}

							// Update cache
							const cachedRequest = cache.readQuery<GetMovables>({
								query: GET_MOVABLES,
							});
							if (cachedRequest === null) {
								return;
							}

							cache.writeQuery({
								query: GET_MOVABLES,
								data: {
									movables: cachedRequest.movables.filter(cm => cm.id !== id),
								},
							});
						},
					});
					callback?.();
				}
			}}
		/>
	);

	const [changeLabelAndStatusModal, setChangeLabelAndStatusModal] = React.useState(false);

	return (
		<>
			<Header
				title="common.movable"
				actions={
					<>
						<div className="mb-3 space-y-2">
							<TextButton text="movable.createMovableJob" icon={faPlus} onClick={() => setShowCreateMovableJobModal(true)} className="mr-4" />
							{/* <TextButton 
                                text="movable.createMovable" 
                                icon={faPlus} 
                                onClick={async () => {
                                    const { data } = await generateMovableIdList();
                                    const [ id ] = data?.generateMovableIdList ?? ['error'];
                                    setShowCreateMovableModal({ type: 'OTHER', movableId: id });
                                }} 
                                className="mr-4" 
                            /> */}
							<TextButton text="movable.createCaseLabels" icon={faPrint} onClick={() => setShowCreatePrintLabelModal(true)} className="mr-4" />
						</div>
						<Button
							primary
							text="common.scanLabel"
							icon={faScanner}
							onClick={() => {
								setScannedId(undefined);
								setShowScannerModal({
									mode: 'SINGLE',
									title: 'common.scanLabel',
									allowedPrefixes: [MOVABLE_CASE_PREFIX, MOVABLE_MOVABLE_PREFIX],
								});
							}}
							className="mr-4 mb-3"
						/>
						<Button
							primary
							text="common.scanMultipleLabels"
							icon={faScanner}
							onClick={() => {
								setScannedId(undefined);
								setShowScannerModal({
									mode: 'MULTIPLE',
									title: 'common.scanMultipleLabels',
									allowedPrefixes: [MOVABLE_CASE_PREFIX, MOVABLE_MOVABLE_PREFIX],
								});
							}}
							className="mr-4 mb-3"
						/>
					</>
				}
			>
				<div className="flex flex-wrap">
					{tabs.map(title => (
						<div key={title} className="mr-3 mt-1">
							<button
								type="button"
								onClick={() => setActiveTab(title)}
								className={classNames('text-blue focus:outline-none', {
									'border-blue border-b-2': title === activeTab,
								})}
							>
								{t(title)}
							</button>
						</div>
					))}
				</div>
			</Header>

			{activeTab === 'common.movable' && (
				<Movables editButton={MovableEditButton} completeButton={MovableCompleteButton} setMovablesVariables={setMovablesVariables} reviveButton={MovableReviveButton} />
			)}
			{activeTab === 'jobs.overviewTitle' && <MovableJobs />}
			{activeTab === 'movable.exportOverviewTitle' && <MovableExportOverview />}

			<Modal
				title="movable.createMovable"
				visible={typeof showCreateMovableModal !== 'undefined'}
				close={() => setShowCreateMovableModal(undefined)}
				body={
					<CreateMovable
						callback={() => setShowCreateMovableModal(undefined)}
						caseId={caseInfo?.id}
						movableId={showCreateMovableModal?.movableId ?? 'error'}
						status={caseInfo?.status ?? ''}
						box={showCreateMovableModal?.type === 'BOX'}
						movablesVariables={movablesVariables}
					/>
				}
			/>

			<Modal
				title="movable.editMovable"
				visible={typeof showEditMovableModal !== 'undefined' && typeof editMovable !== 'undefined'}
				close={() => setShowEditMovableModal(undefined)}
				body={
					typeof editMovable?.movable !== 'undefined' ? (
						<EditMovable key={editMovable.movable.id} movable={editMovable.movable} status={showEditMovableModal?.status ?? ''} callback={() => setShowEditMovableModal(undefined)} />
					) : (
						<></>
					)
				}
			/>

			<Modal
				size={ModalSize.SMALL}
				title="movable.actions"
				visible={typeof movableInfo !== 'undefined'}
				close={() => setMovableInfo(undefined)}
				body={
					typeof movableInfo !== 'undefined' ? (
						<div className="flex flex-row flex-wrap justify-around">
							<div className="w-1/3">
								<h4 className="font-semibold">{t('common.printLabel')}</h4>
								<MovableReprint movableId={movableInfo.id} popoverPlacement="left" callback={() => setMovableInfo(undefined)} className="bg-gray-300 px-10 py-8 text-2xl" />
							</div>

							<div className="w-1/3">
								<h4 className="font-semibold">{t('movable.editMovable')}</h4>
								<MovableEditButton id={movableInfo.id} status={movableInfo.labelStatus} callback={() => setMovableInfo(undefined)} className="px-10 py-8 text-2xl" />
							</div>

							<div className="w-1/3">
								<h4 className="font-semibold">{t('movable.completeMovable')}</h4>
								<MovableCompleteButton id={movableInfo.id} status={movableInfo.labelStatus} callback={() => setMovableInfo(undefined)} className="px-10 py-8 text-2xl" />
							</div>

							<div className="border-t-1 mt-8 w-full border-gray-300 pt-8">
								<Button
									primary
									fullWidth
									text="movable.changeLabelAndStatus"
									onClick={async () => {
										//TODO: Add mutation
										setChangeLabelAndStatusModal(true);
									}}
									className="py-4"
								//disabled={updatingMovable}
								/>
							</div>
						</div>
					) : (
						<></>
					)
				}
			/>

			<Modal
				title={showScannerModal.title}
				close={() => setShowScannerModal({ mode: 'HIDE', title: '' })}
				visible={showScannerModal.mode !== 'HIDE'}
				body={
					showScannerModal.mode === 'WORKING' ? (
						<div className="my-2 py-2 text-center">
							<FontAwesomeIcon size="3x" icon={faSpinner} className="animate-spin" />
						</div>
					) : (
						<BarcodeScanner setScannedId={setScannedId} multiple={showScannerModal.mode === 'MULTIPLE'} allowedPrefixes={showScannerModal.allowedPrefixes} />
					)
				}
				footer={
					showScannerModal.mode === 'MULTIPLE' ? (
						<div className="flex w-full flex-row justify-between">
							<span className="self-center">
								{scannedId?.split(',').length ?? 0} {t('common.scannedItems')}
							</span>

							<Button
								primary
								text="common.scanPlacement"
								onClick={() => {
									scannedIdListRef.current = scannedId?.split(',') ?? [];
									setScannedId(undefined);

									setShowScannerModal({
										mode: 'HIDE',
										title: '',
									});
									window.setTimeout(
										() =>
											setShowScannerModal({
												mode: 'SINGLE',
												title: 'common.scanPlacement',
												allowedPrefixes: [MOVABLE_PLACEMENT_PREFIX],
											}),
										500,
									);
								}}
								disabled={(scannedId?.split(',').length ?? 0) === 0}
							/>
						</div>
					) : undefined
				}
			/>

			{typeof movableInfo !== 'undefined' && typeof caseInfo !== 'undefined' && changeLabelAndStatusModal && (
				<Modal
					size={ModalSize.MEDIUM}
					title="movable.changeLabelAndStatus"
					close={() => setChangeLabelAndStatusModal(false)}
					visible={changeLabelAndStatusModal}
					body={
						<ChangeLabelAndStatus
							oldMovableId={movableInfo.id}
							oldMovableStatus={movableInfo.currentStatus}
							checkMovable={checkMovable}
							movableStatusOptions={movableStatusOptions?.movableStatusOptions ?? []}
							caseId={caseInfo.id}
							close={() => {
								setChangeLabelAndStatusModal(false);
								setMovableInfo(undefined);
								setCaseInfo(undefined);
							}}
						/>
					}
				/>
			)}

			<Modal size={ModalSize.LARGE} title="movable.createCaseLabels" close={() => setShowCreatePrintLabelModal(false)} visible={showCreatePrintLabelModal} body={<CreateCaseLabel />} />

			<Modal
				title="movable.createMovableJob"
				close={() => setShowCreateMovableJobModal(false)}
				visible={showCreateMovableJobModal}
				body={<CreateMovableJob callback={() => setShowCreateMovableJobModal(false)} />}
			/>
		</>
	);
};

export default MovablesTabs;
