import { CalendarEventType, EventFragment } from '@ssg/common/GraphQL/indexV2';
import React from 'react';
import PlannerEvent, { DragPropsEventItem } from './PlannerEvent';
import { useDrop } from 'react-dnd';
import { DragPropsDrivingSlip } from './PlannerDrivingSlipCard';
import { DragItemTypes, isOffDay, useCalendarEvents, useCanEdit } from './PlannerHelpers';
import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';
import { UserCalendarAndColor } from './PlannerSchedule';
import { useTranslation } from 'react-i18next';

interface Props {
	events: EventFragment[];
	user: Pick<UserCalendarAndColor, 'user' | 'color'>;
	date: string;
}
const blockedStyle: React.CSSProperties = {
	backgroundColor: 'rgba(229,229,247,0)',
	opacity: '0.8',
	background: 'repeating-linear-gradient( -45deg, #D31A3A, #D31A3A 10px, rgba(229,229,247,0) 10px, rgba(229,229,247,0) 25px )',
};
const PlannerDaySlot: React.FC<Props> = ({ events, user: { user, color }, date }): React.ReactElement => {
	const { t } = useTranslation();
	const canEdit = useCanEdit();
	const offDay = React.useMemo(() => isOffDay(events), [events]);

	const { postCreateUserCalendarEvent, updateUserEventDeciderAndPost } = useCalendarEvents();

	const [{ isOver, canDrop }, dropRef] = useDrop<
		DragPropsDrivingSlip | DragPropsEventItem,
		{ result: 'denied' | 'success' },
		{
			isOver: boolean;
			canDrop: boolean;
			getItem: DragPropsDrivingSlip | DragPropsEventItem;
		}
	>({
		accept: [DragItemTypes.DRIVING_SLIP, DragItemTypes.EVENT_ITEM],
		canDrop(item, monitor) {
			if ('event' in item) {
				const eventStart = DateTime.fromISO(item.event.timeRange.from + 'Z');

				if (item.oldUserId === user.id && eventStart.toISODate() === date) {
					return false;
				}
			}
			return true;
		},
		drop: item => {
			const dateLength = 'yyyy-MM-dd'.length;
			if ('drivingSlip' in item) {
				const addToOffDay = offDay ? window.confirm(t('planner.confirmOffDay')) : true;
				if (!addToOffDay) {
					return { result: 'denied' };
				}
				// Find out if drivingslip series has multiple drivers
				const drivers = item.drivingSlip.merged.map(d => d.driver?.id);
				const uniqueDriveres = drivers.filter((d, i, a) => a.indexOf(d) === i);
				const hasMultipleDrivers = uniqueDriveres.length > 1;

				//Find out if drivingslip series have multiple dates
				const firstDrivingSlipsStartTime = DateTime.fromISO(date + item.drivingSlip.merged[0].start.substring(dateLength));

				for (const { id, start, end, driver } of item.drivingSlip.merged) {
					const startDaysOffset = Interval.fromISO(`${item.drivingSlip.merged[0].start.substring(0, dateLength)}/${start.substring(0, dateLength)}`).length('days');
					const adjustedStartTime = firstDrivingSlipsStartTime.plus({
						days: startDaysOffset,
					});

					const endTime = adjustedStartTime.plus({
						hours: Interval.fromISO(`${start}/${end}`).length('hours'),
					});
					// If drivingslip has multiple drivers, set each individual driver, else use calendar user
					const setDriver = (hasMultipleDrivers ? (typeof driver?.id !== 'undefined' ? driver?.id : user.id) : user.id);

					postCreateUserCalendarEvent(setDriver, {
						title: item.drivingSlip.case.erpNo,
						type: CalendarEventType.DrivingSlip,
						timeRange: {
							from: adjustedStartTime.toUTC().toISO(),
							to: endTime.toUTC().toISO(),
						},
						drivingSlip: id,
					});
				}
			} else if ('event' in item) {
				const eventStart = DateTime.fromISO(item.event.timeRange.from + 'Z');
				if (item.oldUserId === user.id && eventStart.toISODate() === date) {
					return { result: 'denied' };
				}
				const addToOffDay = offDay ? window.confirm(t('planner.confirmOffDay')) : true;
				if (!addToOffDay) {
					return { result: 'denied' };
				}

				const eventEnd = DateTime.fromISO(item.event.timeRange.to + 'Z');
				const realDurationInHours = eventEnd.diff(eventStart, 'hours').toObject().hours ?? 0;
				const newStartTime = DateTime.fromISO(date + item.event.timeRange.from.substring(dateLength) + 'Z');
				const endTime = newStartTime.plus({ hours: realDurationInHours });
				updateUserEventDeciderAndPost(
					item.event.referenceId,
					{
						title: item.event.title,
						type: item.event.type,
						drivingSlip: item.event.drivingSlip?.id,
						timeRange: {
							from: newStartTime.toUTC().toISO(),
							to: endTime.toUTC().toISO(),
						},
					},
					user.id,
					item.oldUserId,
				);
			}
			return { result: 'success' };
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			getItem: monitor.getItem(),
		}),
	});

	const ref = canEdit ? dropRef : null;

	return (
		<div
			ref={ref}
			className={classNames('h-full w-full p-1 space-y-2 pb-3', isOver && color, { 'cursor-copy': isOver && canDrop })}
			style={offDay ? blockedStyle : undefined}
		>
			{events.map(e => <PlannerEvent key={e.referenceId} event={e} user={user} />)}
		</div>
	);
};

export default PlannerDaySlot;